import { makeStyles } from "@fluentui/react-components";
import {
  AirplaneLandingFilled,
  AirplaneTakeOffFilled,
} from "@fluentui/react-icons";
import React from "react";
import {
  $cloud100,
  $cloud30,
  $cloud60,
  $cloud70,
  $sky60,
} from "../../../../../../cssjs/colors";
import { IFlightListCard } from "../../../../../../models/flights/IFlightListCard";
import { FlightStatus } from "../FlightStatus";
import LiveTag from "./LiveTag";

const spaceBetween = "space-between";

export const FlightListCard: React.FC<IFlightListCard> = ({
  id,
  flightNumber,
  aircraftTypeName,
  callSign,
  tailNumber,
  origin,
  destination,
  atd,
  eta,
  totalPax,
  onGround,
  flyingStatus,
  trafficType,
  liveTracked,
  setShowFlightDetails,
  setFlightCardListData,
  legType,
}): React.JSX.Element => {
  const classes = useStyles();
  const flightTrackingProps = {
    id,
    origin,
    destination,
    onGround,
    flyingStatus,
  };
  const trafficText = trafficType === "International" ? "INTL" : "DOM";

  const state = {
    id,
    flightNumber,
    aircraftTypeName,
    callSign,
    tailNumber,
    origin,
    destination,
    atd,
    eta,
    totalPax,
    onGround,
    flyingStatus,
    trafficType,
    liveTracked,
    legType,
  };

  const handleClick = () => {
    //@ts-ignore
    setShowFlightDetails((prev) => !prev);
    setFlightCardListData(state);
  };

  return (
    <>
      <section
        className={classes.card}
        onClick={handleClick}
        data-testid="flight-list-card"
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardDetails}>
            <div className={classes.cardTitleSection}>
              <div className={classes.cardHeaderInfo}>
                <div className={classes.cardTitleText}>{flightNumber}</div>
                <div className={`fs-20 ${classes.cardFlightStatus}`}>
                  {legType === "arrival" ? (
                    <AirplaneTakeOffFilled />
                  ) : (
                    <AirplaneLandingFilled />
                  )}
                </div>
                {liveTracked && <div className={classes.liveTag}><LiveTag liveTracked={liveTracked} /></div>}
              </div>
              <div>
                <FlightStatus key={id} {...flightTrackingProps} />
              </div>
            </div>
            <div className={classes.cardSubTitle}>
              <div>{aircraftTypeName}</div>
              <div>{tailNumber}</div>
              <div>{callSign}</div>
            </div>
          </div>
        </div>
        <div className={classes.cardFooter}>
          <div>
            <span className={classes.cardFooterHighlight}>ATD</span> {atd}
          </div>
          <div>
            <span className={classes.cardFooterHighlight}>ETA</span> {eta}
          </div>
          <div>
            <span className={classes.cardFooterHighlight}>PAX</span> {totalPax}
          </div>
          <div>
            <span className={classes.cardFooterHighlight}>{trafficText}</span>
          </div>
        </div>
      </section>
    </>
  );
};

const useStyles = makeStyles({
  card: {
    maxHeight: "80px",
    borderBottom: `1px solid ${$cloud30}`,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    color: $cloud100,
    padding: "12px 0 8px",
    ":hover": {
      cursor: "pointer",
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    paddingRight: "4px",
  },
  cardDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardTitleSection: {
    display: "flex",
    justifyContent: spaceBetween,
    alignItems: "center",
  },
  cardHeaderInfo: {
    display: "flex",
    alignItems: "center",
  },
  cardTitleText: {
    fontSize: "16px",
    paddingRight: "12px",
    marginBottom: "1px"
  },
  cardSubTitle: {
    display: "flex",
    justifyContent: spaceBetween,
    color: $cloud70,
    fontSize: "12px",
  },
  cardFooter: {
    display: "flex",
    justifyContent: spaceBetween,
    fontSize: "12px",
    paddingRight: "4px",
  },
  cardFooterHighlight: {
    color: $cloud60,
  },
  flightDetailsPosition: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    top: "0",
    left: "0",
    background: $cloud100,
  },
  cardFlightStatus: {
    color: $sky60,
    paddingRight: "12px"
  },
  liveTag: {
    "& .fui-Tag ": {
      maxHeight: "14px"
    },
    "& .fui-Tag__primaryText": {
      lineHeight: "1.2"
    }
  }
});
