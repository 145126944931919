import { makeStyles } from "@fluentui/react-components";
import React from "react";
import { FlightListCard } from "./FlightListCard";
import { FlightTabsEnum } from "../FlightTabs";
import { IFlightListCard } from "../../../../../../models/flights/IFlightListCard";
import { FlightListContext } from "../../../../../../context/FlightListContext";

const useStyles = makeStyles({
  tabPanelContainer: {
    position: "relative",
  },
});

const FlightListTabContent: React.FC<{
  classes: ReturnType<typeof useStyles>;
  data: IFlightListCard[];
  setShowFlightDetails: (prev: boolean) => void;
  setFlightCardListData: any;
  type: FlightTabsEnum;
}> = ({ classes, data, setShowFlightDetails, setFlightCardListData, type}) => {

    let filteredData;
    let ariaLabelledBy = 'All Arrivals & Departures';
    const { selectedOptions }: { selectedOptions: string[] } = React.useContext(FlightListContext);
    switch (type) {
      case FlightTabsEnum.Arrivals:
        filteredData = data.filter((flight) => flight.legType === FlightTabsEnum.Arrivals);
        ariaLabelledBy = 'Arrivals';
        break;
      case FlightTabsEnum.Departures:
        filteredData = data.filter((flight) => flight.legType === FlightTabsEnum.Departures);
        ariaLabelledBy = 'Departures';
        break;
      case FlightTabsEnum.All:
      default:
        filteredData = data;
        break;
    }

  // Further filter the data based on selectedOptions
  if (selectedOptions.length > 0) {
  filteredData = filteredData.filter((flight) =>
    selectedOptions.includes(flight.flightNumber) // Replace someProperty with the actual property you want to filter by
  );
}

  return (<div
    role="tabpanel"
    aria-labelledby={ariaLabelledBy}
    className={classes.tabPanelContainer}
  >
    {filteredData?.map((flight) => (
      <FlightListCard key={flight.id} {...flight} setShowFlightDetails={setShowFlightDetails} setFlightCardListData={setFlightCardListData}/>
    ))}
  </div>)
};

export default FlightListTabContent;
