import L from "leaflet";
import "leaflet-rotatedmarker";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef } from "react";
import { Marker, Tooltip } from "react-leaflet";
import { flightStatus } from "../../models/airports/AirportConfig";
import { tooltipStyles } from "./mapStyles";
import { getFlightIcon } from "./marker-icons/flightIcons";

export interface IFlightMarkerProps {
  id: string;
  airportCode: string;
  flightNumber: string;
  position: [number, number];
  direction: number;
  flightStatus: flightStatus;
  legType: string;
}

const FlightMarker: React.FC<IFlightMarkerProps> = ({
  airportCode,
  flightNumber,
  position,
  flightStatus = "default",
  direction,
  legType,
}) => {
  const markerRef = useRef(null);
  const classes = tooltipStyles();

  const tooltipContent =
    legType === "arrival" ? "Arriving at" : "Departed from";

  useEffect(() => {
    if (markerRef.current) {
      const currentMarker = markerRef.current as L.Marker;
      currentMarker.setLatLng(position);
      currentMarker.setRotationAngle(direction);
    }
  }, [position, direction]);

  return (
    <Marker
      position={position}
      icon={getFlightIcon(flightStatus)}
      ref={markerRef}
    >
      <Tooltip direction="top" className={classes.tooltip}>
        <div className="px-1 text-c fw-500">
          Flight no: <strong>{flightNumber}</strong> <br />
          {tooltipContent} {airportCode}
        </div>
      </Tooltip>
    </Marker>
  );
};

export default FlightMarker;
