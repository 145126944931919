export const $primary = "#005EB8";
export const $purple = "#6F2B8D";
export const $cherry = "#CE0058";
export const $amadeusGray400 = "#999999";
export const $white = "white";
export const $black = "black";
export const $gray200 = "#cccccc";
export const $gray600 = "#666666";
export const $gray700 = "#4d4d4d";
export const $gray800 = "#333333";
export const $gray900 = "#1a1a1a";
export const $darkPrimary = "#004485";
export const $brown = "#9e6900";
export const $dangerbg = "#fedfdf";
export const $warningbg = "#fef4e4";
export const $safebg = "#e0f4ea";
export const $red = "#c60000";
export const $darkorange = "#e95326";
export const $green = "#008540";
// Multi-airport(tags) pills
export const $allBgSelected = "rgba(235, 243, 255)";
export const $allBorderSelected = $primary;
export const $allTextColorSelected = $primary;

export const $pinnedBg = "#F8EEFF";
export const $pinnedBgSelected = "rgba(112, 51, 156)";
export const $pinnedTextColor = "#461B64";
export const $pinnedTextColorSelected = $white;

export const $goodBg = "#F0FFEC";
export const $goodBgSelected = "rgba(58, 102, 46, 1)";
export const $goodTextColor = "#3A662E";
export const $goodTextColorHover = $white;

export const $moderateBg = "#FFF0E6";
export const $moderateBgSelected = "rgba(250, 100, 0)";
export const $moderateTextColor = "#FA6400";
export const $moderateTextColorSelected = $white;

export const $poorBg = "#FFEEED";
export const $poorBgSelected = "rgba(222, 47, 38)";
export const $poorTextColorSelected = $white;
export const $poorTextColor = "#B2271F";

// New Design Styles
export const $goodStatus = "#00A34E";
export const $sky50 = "#3A8BFF";
export const $sky60 = "#0C66E1";
export const $sky90 = "#000835";
export const $cloud10 = "#FAFAFA";
export const $cloud20 = "#F2F2F2";
export const $cloud30 = "#E6E6E6";
export const $cloud40 = "#CCCCCC";
export const $cloud60 = "#999999";
export const $cloud70 = "#808080";
export const $cloud50 = "#B3B3B3";
export const $cloud80 = "#666666";
export const $cloud90 = "#333333";
export const $cloud100 = "#1A1A1A";
export const $cloud110 = "#000000";
export const $forest60 = "#6DC354";
export const $forest70 = "#569643";
export const $crimson60 = "#DF3127";
export const $pumpkin40 = "#FF8838";

