import { Spinner } from "@fluentui/react-components";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import PAX_FILTERS from "../../data/filter-panel/pax-filter.json";
import PAX_FILTERS_XYZ from "../../data/filter-panel/pax-filter_XYZ.json";
import ResourceEnum from "../../enum/ResourceEnum";
import { useApiCall } from "../../hooks/useApiCall";
import IFilters from "../../models/filters/IFilters";
import { IFlightHourFilter } from "../../models/flights/IFlightHour";
import { IPillButton } from "../../models/flights/IFlightPillButton";
import { IFlightScheduleFilters } from "../../models/flights/IFlightScheduleFilters";
import IPassengerGraph, {
  IGroupedBarData,
} from "../../models/pax/IPassengerGraph";
import { getMemoizedPaxFilters } from "../../store/pax-filter/PaxFilterSelector";
import { paxFilterActions } from "../../store/pax-filter/PaxFilterSlice";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import { AIRPORT_CODE_PARAMETER } from "../../utils/common/Constants";
import DateHelper from "../../utils/common/DateHelper";
import { FlightListHelper } from "../../utils/flights/FlightListHelper";
import FlightScheduleHelper from "../../utils/flights/FlightScheduleHelper";
import { PassengerHelper } from "../../utils/pax/PassengerHelper";
import { roundTimetoStartHour } from "../../utils/pax/roundTimetoStartHour";
import { GroupedBarChart } from "../common/charts/GroupedBarChart/GroupedBarChart";
import { AmdTabs } from "../common/tabs/tabs";
import { FilterPanel } from "../filters/filter-panel/FilterPanel";
import { FlightPillButtonFilter } from "../filters/flight-pillbuttons/FlightPillButtonFilter";
import { FlightTypeFilter } from "../filters/flight-type/FlightTypeFilter";
import { FlightListPagination } from "../filters/pagination/FlightListPagination";
import { FlightDetailList } from "../flights/flights-list/FlightDetailList";
import { PassengerKPI } from "./PassengerKPI";

const defaultHourValue = 12;

const hourFilterButtons: IFlightHourFilter[] =
  FlightScheduleHelper.getHourFilterButtons();

export const PassengerMacroCard: React.FunctionComponent<
  IPassengerMacroCard
> = (props: IPassengerMacroCard) => {
  const pillButtons: IPillButton[] = FlightScheduleHelper.getPillButtons(
    props.airport
  );
  const [flightListState, setFlightListState] =
    useState<IFlightScheduleFilters>(
      FlightScheduleHelper.getDefaultFlightListState(
        props.airport,
        defaultHourValue,
        ResourceEnum.ResourceType.Passenger
      )
    );
  const [selectedHourFilter, setSelectedHourFilter] = useState<string>("1");
  const [totalFlights, setTotalFlights] = useState<number>();

  const flightListServicePayload =
    FlightListHelper.getFlightListServicePayload(flightListState);

  const getPassengerGraphDataUrl = `${process.env.REACT_APP_API_PAX_GRAPH?.replace(
    AIRPORT_CODE_PARAMETER,
    props.airport
  )}`;

  const { start } = flightListServicePayload;

  const roundedStartTime = roundTimetoStartHour(start);

  const updatedFlightListServicePayload = {
    ...flightListServicePayload,
    start: roundedStartTime ?? start,
  };

  const {
    data: passengerGraphData,
    error,
    isLoading,
  } = useApiCall<IPassengerGraph[]>({
    url: getPassengerGraphDataUrl,
    requestType: "post",
    payload: updatedFlightListServicePayload,
    dependencies: [flightListState.flightType],
  });

  const groupedBarData: IGroupedBarData[] = passengerGraphData?.length
    ? PassengerHelper.getGroupedBarData(passengerGraphData)
    : [];

  const localStorageFiltersKey = `${props.airport}paxFilters`;
  const dispatch = useAppDispatch();
  const filterObj = localStorage.getItem(localStorageFiltersKey);
  let filters: IFilters[] = [];
  if (filterObj) {
    filters = JSON.parse(filterObj);
  }

  const setPaxFilters = () => {
    if (filters && filters.length > 0) {
      dispatch(
        paxFilterActions.setPaxFilters({
          airport: props.airport,
          filter: filters,
        })
      );
      const selectedItems = FlightScheduleHelper.getSelectedItems(filters);
      const flightListState =
        FlightScheduleHelper.getFlightlistState(selectedItems);
      onFiltersApplied(flightListState);
    } else {
      const airportPaxFilter =
        props.airport === "XYZ" ? PAX_FILTERS_XYZ.data : PAX_FILTERS.data;
      dispatch(
        paxFilterActions.setPaxFilters({
          airport: props.airport,
          filter: airportPaxFilter,
        })
      );
    }
  };

  const paxfilters = useAppSelector(getMemoizedPaxFilters);

  useEffect(() => {
    FlightScheduleHelper.scrollToComponent(
      window.sessionStorage.getItem("scrollId")?.toString()
    );
    window.sessionStorage.removeItem("scrollId");

    setPaxFilters();
  }, [dispatch]);

  const onFlightTypeChange = (_flightType: string) => {
    const stateObj = { ...flightListState };
    stateObj.flightType =
      _flightType.toLowerCase() === "all" ? "arrival;departure" : _flightType;
    setFlightListState(stateObj);
  };

  const onPillButtonClick = (selectedButtons: []) => {
    const stateObj = { ...flightListState };
    stateObj.status = "";
    stateObj.destinationType = "";
    stateObj.terminal = "";
    selectedButtons.forEach((selectedButton: string) => {
      FlightScheduleHelper.setUpdatedStateObject(selectedButton, stateObj);
      setFlightListState(stateObj);
    });
  };

  const onHourFilterClick = (_filterString: string) => {
    setSelectedHourFilter(_filterString);
    const currentDate = new Date(
      DateHelper.getCurrentDateTimeStrForAirport(props.airport)
    );
    const stateObj = { ...flightListState };
    const defaultEndDateString = DateHelper.getFormattedTime(
      moment(currentDate).add(parseFloat(_filterString), "hours").toDate()
    );

    stateObj.start = DateHelper.getFormattedTime(currentDate);
    stateObj.end = defaultEndDateString;
    setFlightListState(stateObj);
  };

  const onPageDropDownChange = (_pageNumber: string) => {
    const stateObj = { ...flightListState };
    stateObj.page = _pageNumber;
    setFlightListState(stateObj);
  };

  const getFlightTotal = (_total: number) => {
    setTotalFlights(_total);
  };

  const onUpdateFilter = (updatedItems: IFilters[]) => {
    dispatch(
      paxFilterActions.setPaxFilters({
        airport: props.airport,
        filter: updatedItems,
      })
    );
  };

  const onFiltersApplied = (_filters: IFlightScheduleFilters) => {
    const stateObj = FlightScheduleHelper.getDefaultFlightListState(
      props.airport,
      defaultHourValue,
      ResourceEnum.ResourceType.Passenger
    );
    const updatedStateObj = FlightScheduleHelper.onFlightScheduleFiltersApplied(
      stateObj,
      _filters,
      props.airport
    );
    setFlightListState(updatedStateObj);
  };

  return (
    <>
      {!props.isDetailsPage && (
        <>
          <PassengerKPI airport={props.airport} />
          <section
            className="mt-4 amd-container-hspacebtwn"
            data-testid="passenger-macro-card"
          >
            <FlightTypeFilter
              onChange={(changedFlightType: string) => {
                onFlightTypeChange(changedFlightType);
              }}
              selectedType={flightListState.flightType}
            />
            <AmdTabs
              position="right"
              defaultSelectedTab="1"
              tabList={hourFilterButtons}
              dataTestId="HourFilterTab"
              onTabClick={onHourFilterClick}
              selectedTab={selectedHourFilter}
            />
          </section>
          <section className="mt-sm-1 mb-sm-2 amd-container-hspacebtwn">
            <FlightPillButtonFilter
              buttons={pillButtons}
              onPillButtonClick={onPillButtonClick}
            />
            <FlightListPagination
              onDropDownChange={onPageDropDownChange}
              flightCount={totalFlights}
            />
          </section>
        </>
      )}
      {props.isDetailsPage && (
        <>
          <FlightTypeFilter
            onChange={(changedFlightType: string) => {
              onFlightTypeChange(changedFlightType);
            }}
            selectedType={flightListState.flightType}
          />
          <FilterPanel
            filters={paxfilters}
            onFiltersApplied={onFiltersApplied}
            onUpdateFilter={onUpdateFilter}
            airport={props.airport}
          />
        </>
      )}
      <FlightDetailList
        flightListState={flightListState}
        selectedType={flightListState.flightType}
        getFlightTotal={getFlightTotal}
        viewType={ResourceEnum.ResourceType.Passenger}
        airport={props.airport}
      />
      {props.isDetailsPage && (
        <div style={{ marginTop: "20px" }} className="groupedBarSection">
          {isLoading && (
            <Spinner
              labelPosition="below"
              label="Loading..."
              style={{ height: "35vh" }}
            />
          )}
          {!error && !isLoading && (
            <GroupedBarChart chartData={groupedBarData} />
          )}
        </div>
      )}
    </>
  );
};

export interface IPassengerMacroCard {
  isDetailsPage: boolean;
  airport: string;
}
