import React from "react";

export const FlightIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        d="M4.75168 1.28247C5.03656 1.01682 5.70581 0.739052 5.96411 1.01605C6.22979 1.30096 5.89864 1.94129 5.61376 2.20694L4.63703 3.11775L4.9879 6.35341C5.00038 6.41575 4.98182 6.47745 4.93299 6.52299L4.85185 6.59865C4.76231 6.68215 4.62339 6.66372 4.57401 6.56181L3.45816 4.21707L2.1079 5.47621L2.21909 6.31347C2.22419 6.3679 2.20639 6.41409 2.1657 6.45204L2.13612 6.47962C2.04659 6.56311 1.98595 6.49165 1.90635 6.41275L1.10164 5.55038L0.304874 4.69538C0.227836 4.61334 0.157286 4.53708 0.246821 4.45359L0.276394 4.42601C0.317091 4.38806 0.371789 4.38144 0.425728 4.39033L1.24582 4.55174L2.59608 3.2926L0.342267 2.02382C0.244047 1.96745 0.227982 1.81967 0.317517 1.73618L0.398657 1.66052C0.447494 1.61497 0.517711 1.60868 0.57903 1.62548L3.77495 2.19328L4.75168 1.28247Z"
        fill="white"
      />
    </svg>
  );
};
