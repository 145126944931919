import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbItem,
  Button,
  makeStyles,
  Switch,
} from "@fluentui/react-components";
import React from "react";
import { useParams } from "react-router-dom";
import { LayoutContext } from "../../../context/LayoutContext";
import { $cloud50 } from "../../../cssjs/colors";
import PageEnum from "../../../enum/PageEnum";
import useMediaQuery from "../../../hooks/useMediaQuery";
import DateFilter from "../../filters/date-filter/DateFilter";
import GlobalFilter from "../../filters/global-filter/GlobalFilter";
import { MapSignsIcon } from "../icons/map-signs/MapSignsIcon";
import QuickNavBar from "../quick-nav-bar/QuickNavBar";

const Layout: React.FunctionComponent<{ children?: React.ReactElement }> = ({
  children,
}) => {
  const [isMapView, setIsMapView] = React.useState<boolean>(false);
  const isOverview = location?.pathname?.startsWith("/overview");
  const classes = useStyles();

  //To update the deafult value in future based on the Multi_Aiport api
  const { id = "XYZ" } = useParams();

  const isTabletPortrait = useMediaQuery("(max-width: 1024px)");
  const [showQuickNav, setShowQuickNav] = React.useState<boolean>(false);

  const isTabletLandscape = useMediaQuery(
    "(orientation: landscape) and (max-width: 1370px)"
  );

  //Calculate the height of the map view container relative to the screen size
  const minHeight = isTabletLandscape
    ? "calc(100vh - 286px)"
    : "calc(100vh - 182px)";

  const handleMapView = React.useCallback(
    (ev: {
      currentTarget: { checked: boolean | ((prevState: boolean) => boolean) };
    }) => {
      setIsMapView(ev.currentTarget.checked);
    },
    [setIsMapView]
  );

  const toggleQuickNavBar = () => {
    setShowQuickNav((prev) => !prev);
  };

  const globalFilterLabel = isTabletPortrait ? "" : "Global";
  const contextValue = React.useMemo(() => ({ isMapView }), [isMapView]);

  return (
    <main data-testid="layoutContainer">
      <div className="amd-container-vcenter amd-container-hspacebtwn mb-2">
        <Breadcrumb aria-label="Overview Breadcrumbs">
          <BreadcrumbItem>
            <BreadcrumbButton href={`/${PageEnum.PageUrl.MultiAirports}`}>
              <h1
                className={`${classes.airportHeading} fw-500 fs-16`}
                data-testid="multi-airport"
              >
                Airports list
              </h1>
            </BreadcrumbButton>
          </BreadcrumbItem>
          {isOverview && (
            <>
              /
              <BreadcrumbItem>
                <BreadcrumbButton className={classes.breadcrumbsIcon} current>
                  {id}
                </BreadcrumbButton>
              </BreadcrumbItem>
            </>
          )}
        </Breadcrumb>
        <div className="amd-container-center">
          <Switch
            checked={isMapView}
            label={`Map view`}
            labelPosition="after"
            onChange={handleMapView}
          />

          <DateFilter />
          <GlobalFilter label={globalFilterLabel} />
          {isTabletPortrait && (
            <Button
              className={classes.iconButton}
              onClick={toggleQuickNavBar}
              icon={{
                as: "span",
                children: <MapSignsIcon color="#0E5EBB" />,
              }}
            >
              <span className="fs-14 fw-600">Q-Nav</span>
            </Button>
          )}
        </div>
      </div>

      <LayoutContext.Provider value={contextValue}>
        <section
          className={isMapView ? classes.mapViewContainer : undefined}
          style={{ minHeight: minHeight }}
        >
          {children}
          {(!isTabletPortrait || showQuickNav) && <QuickNavBar />}
        </section>
      </LayoutContext.Provider>
    </main>
  );
};

export default Layout;

const useStyles = makeStyles({
  airportHeading: {
    color: "#000835",
    "&:hover": {
      color: "#0C66E1 !important",
    },
  },
  breadcrumbsIcon: {
    flexDirection: "row-reverse",
    display: "flex",
  },
  iconButton: {
    border: "1px solid #0C66E1",
    padding: "5px",
    margin: "0px",
    color: "#0C66E1",
  },
  mapViewContainer: {
    background: "#F8F8F8",
    paddingTop: "8px",
    borderRadius: "8px",
    border: `1px solid ${$cloud50}`,
    position: "relative",
  },
});
