import {
  SearchBoxChangeEvent,
} from "@fluentui/react-components";
import React from "react";
import Sidebar from "../common/sidebar/Sidebar";
import { OverviewCarousel } from "./OverviewCarousel";

interface IAirportListProps {
  sideBarActive: boolean;
  setSideBarActive: any;
  searchInputHandler: (event: SearchBoxChangeEvent) => void;
}

export const OverviewSidebar: React.FC<IAirportListProps> = ({
  sideBarActive,
  setSideBarActive,
  searchInputHandler,
}) => {

  return (
    <Sidebar
      sideBarActive={sideBarActive}
      setSideBarActive={setSideBarActive}
      searchInputHandler={searchInputHandler}
    >
     <OverviewCarousel />
    </Sidebar>
  );
};
