import { SearchBoxChangeEvent } from "@fluentui/react-components";
import React from "react";
import MapDisplayFlights from "../../../components-v2/osm-maps/MapDisplayFlights";
import { OverviewSidebar } from "../../../components-v2/overview-sidebar/OverviewSidebar";
import { LayoutContext } from "../../../context/LayoutContext";
import { getAirportCode } from "../../../store/airport/AirportSelector";
import { useAppSelector } from "../../../store/StoreHooks";
import FlightScheduleHelper from "../../../utils/flights/FlightScheduleHelper";
import { findMatches } from "../../../utils/multi-airports/MultiAirportHelperFunctions";
import { NotificationBanner } from "../../common/notification-banner/NotificationBanner";
import { FlightsKPI } from "../flights-360/FlightsKPI";
import { FlightsMacroCard } from "../flights-360/FlightsMacroCard";
import { OTPMacroCard } from "../otp-flights/OTPMacroCard";

export const FlightsSchedule: React.FunctionComponent =
  (): React.JSX.Element => {
    const airport = useAppSelector((state) => getAirportCode(state));
    const { isMapView } = React.useContext(LayoutContext);
    const [sideBarActive, setSideBarActive] = React.useState<boolean>(true);

    function searchInputHandler(event: SearchBoxChangeEvent): void {
      const { target } = event;
      const { value } = target as HTMLInputElement;
      const matchedAirports = findMatches(value, []);
      console.log("matchedAirports", matchedAirports);
      // set flights state
    }

    return (
      <>
        {isMapView ? (
          <>
            <OverviewSidebar
              sideBarActive={sideBarActive}
              setSideBarActive={setSideBarActive}
              searchInputHandler={searchInputHandler}
            />
            <MapDisplayFlights
              airportMapDetail={{
                id: "XYZ",
                code: "XYZ",
                name: "XYZ International Airport",
                location: [48.354, 11.7884],
              }}
            />
          </>
        ) : (
          <>
            <NotificationBanner airport={airport} />
            <h1 className="amd-heading1">Flight 360</h1>
            <FlightsKPI displayOtp={false} airport={airport} />
            <section className="amd-section-shadow my-4" id="flight">
              <FlightsMacroCard isDetailsPage={true} airport={airport} />
            </section>
            <section className="amd-section-shadow" id="otp">
              <OTPMacroCard
                dimensionName={FlightScheduleHelper.getDefaultSelectedDimension()}
                airport={airport}
              />
            </section>
          </>
        )}
      </>
    );
  };
