import { makeStyles } from "@fluentui/react-components";

export const mapStyles = makeStyles({
  mapContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    zIndex: 0,
  },
});

export const tooltipStyles = makeStyles({
  tooltip: {
    fontWeight: 600,
    fontSize: "12px",
    opacity: 0.5,
  },
});
