import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { IAirportMap } from "../../models/airports/AirportConfig";
import AirportMarkers from "./AirportMarkers";
import { mapStyles } from "./mapStyles";

export interface IMapDisplayAirports {
  airportMapDetails: IAirportMap[];
}

const MapDisplayAirports: React.FC<IMapDisplayAirports> = ({
  airportMapDetails = [],
}) => {
  const classes = mapStyles();

  const airportMarkersContent = airportMapDetails.map((airport) => (
    <AirportMarkers
      key={airport.id}
      code={airport.code}
      name={airport.name}
      position={airport.location}
    />
  ));

  return (
    <div className={classes.mapContainer} data-testid="multi-airport-map">
      <MapContainer
        center={[48.9463, 10.3554]}
        zoom={7}
        style={{ height: "100%", width: "100%", borderRadius: "8px" }}
        zoomControl={false}
        minZoom={4}
      >
        <ZoomControl position="bottomright" />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='© <a href="https://www.openstreetmap.org/copyright">OSM</a>'
        />
        {airportMarkersContent}
      </MapContainer>
    </div>
  );
};

export default MapDisplayAirports;
