import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { useApiCall } from "../../hooks/useApiCall";
import {
  IAirportMap,
  IFlightMarker,
} from "../../models/airports/AirportConfig";
import { AIRPORT_CODE_PARAMETER } from "../../utils/common/Constants";
import AirportMarkers from "./AirportMarkers";
import FlightMarker from "./FlightMarker";
import { mapStyles } from "./mapStyles";

export interface IMapDisplayFlights {
  airportMapDetail: IAirportMap;
}

//Define the base URL and the endpoints
const flightTrackingURL = process.env.REACT_APP_API_FLIGHT_TRACKING;
const baseURL = process.env.REACT_APP_API_BASE_URL;
const url = `${baseURL}${flightTrackingURL}`;

const MapDisplayFlights: React.FC<IMapDisplayFlights> = ({
  airportMapDetail,
}) => {
  const classes = mapStyles();

  const urlWithParams = url?.replace(
    AIRPORT_CODE_PARAMETER,
    airportMapDetail.id
  );

  const { data: flightData } = useApiCall<IFlightMarker[]>({
    url: urlWithParams,
    dependencies: [airportMapDetail.id],
    poll: 0,
  });

  const flightMarkers =
    flightData &&
    flightData?.map((flight: IFlightMarker) => (
      <FlightMarker
        key={flight.id}
        airportCode={airportMapDetail.code}
        id={flight.id}
        flightNumber={flight.flightNumber}
        direction={flight.direction}
        position={[flight.latitude, flight.longitude]}
        flightStatus={flight.status}
        legType={flight.legType}
      />
    ));

  return (
    <div className={classes.mapContainer} data-testid="flight-tracking-map">
      <MapContainer
        center={airportMapDetail.location}
        zoom={7}
        style={{ height: "100%", width: "100%", borderRadius: "8px" }}
        zoomControl={false}
        minZoom={4}
      >
        <ZoomControl position="bottomright" />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='© <a href="https://www.openstreetmap.org/copyright">OSM</a>'
        />
        <AirportMarkers
          key={airportMapDetail.id}
          code={airportMapDetail.code}
          name={airportMapDetail.name}
          position={airportMapDetail.location}
        />
        {flightMarkers}
      </MapContainer>
    </div>
  );
};

export default MapDisplayFlights;
