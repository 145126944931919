import { makeStyles } from "@fluentui/react-components";
import React from "react";
import {
  $cloud100,
  $cloud40,
  $cloud60,
  $cloud70,
  $cloud80,
  $sky90,
  $white,
} from "../../../../../../cssjs/colors";
import { FlightStatus } from "../FlightStatus";
import { AmdChevron } from "../../../../../common/icons/chevron/chevron";
import { ChevronDirection } from "../../../../../common/icons/chevron/chevronDirection";
import { IFlightListCard } from "../../../../../../models/flights/IFlightListCard";
import mockFlightDetailsData from "../../../../../../../src/mock/flights/mockFlightDetailsData.json";
import LiveTag from "./LiveTag";

export const FlightDetails: React.FC<{
  setShowFlightDetails: (prev: boolean) => void;
  flightCardListData: IFlightListCard | null;
}> = ({ setShowFlightDetails, flightCardListData }) => {
  const classes = useStyles();

  const {
    flightNumber,
    aircraftTypeName,
    liveTracked,
    callSign,
    trafficType,
  } = flightCardListData ?? {};
  const trafficText = trafficType === "International" ? "INTL" : "DOM";

  const handleClick = () => {
    //@ts-ignore
    setShowFlightDetails((prev) => !prev);
  };

  const flightDetails = mockFlightDetailsData.data.find(
    (flight) => flight.id === flightCardListData?.flightNumber
  );

  if (!flightDetails) {
    return <div>No flight details found.</div>;
  }

  const details = [
    { title: "Status", value: flightDetails.status },
    { title: "Terminal", value: flightDetails.terminal },
    { title: "PAX", value: flightDetails.pax },
    { title: "Gate", value: flightDetails.gate },
    { title: "Baggage belt", value: flightDetails.baggageBelt },
    { title: "Altitude", value: flightDetails.altitude },
    { title: "Latitude", value: flightDetails.latitude },
    { title: "Longitude", value: flightDetails.longitude },
    { title: "Speed", value: flightDetails.speed },
    {
      title: "Average flight time",
      value: flightDetails.averageFlightTime,
    },
    {
      title: "Heading direction",
      value: flightDetails.headingDirection,
    },
    {
      title: "Vertical rate of ascent",
      value: flightDetails.verticalRateOfAscent,
    },
    { title: "SQUAWK", value: flightDetails.squawk },
  ];

  const times = [
    { label: "STD", value: flightCardListData?.std },
    { label: "STA", value: flightCardListData?.sta },
    { label: "ATD", value: flightCardListData?.atd },
    { label: "ETA", value: flightCardListData?.eta },
  ];

  return (
    <div className={classes.card} data-testid="flight-details-card">
      <div className={classes.cardHeader}>
        <div className={classes.backButton} onClick={handleClick}>
          <AmdChevron direction={ChevronDirection.RIGHT} />
        </div>
        <p className={classes.buttonText}>{flightNumber}</p>
        <div className={classes.liveTag}>
          {liveTracked && <LiveTag liveTracked={liveTracked} />}
        </div>
      </div>

      <section className={classes.infoSection}>
        <div className={classes.infoDetails}>
          <div>{aircraftTypeName}</div>
          <div>{flightNumber}</div>
          <div>{trafficText}</div>
          <div>CALLSIGN: {callSign}</div>
        </div>
        <div className={classes.flightStatus}>
          <FlightStatus {...flightCardListData} isDetails={true} />
        </div>
        <div>
        <div className={classes.infoTimes}>
          {times?.map((time) => (
              <div key={time.label}>
                <span className={classes.timeDisplayText}>{time.label}</span>{" "}
                {time.value ? time.value : "N/A"}
              </div>
          ))}
          </div>
        </div>
      </section>

      <section className={classes.specificationSection}>
        <div className={classes.specTitle}>Flight Details</div>
        <div className={classes.specPanel}>
          <div className={classes.panelContent}>
            {details.map((detail) => (
              <div key={mockFlightDetailsData.data[0].id} className={classes.specRow}>
                <div className={classes.specTitleCell}>{detail.title}</div>
                <div>{detail.value}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

const useStyles = makeStyles({
  card: {
    padding: "0px 16px 16px 16px",
    background: $white,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    zIndex: 10000,
    width: "100%",
    maxWidth: "362px",
    minHeight: "609px",
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    padding: "8px 0px",
    marginBottom: "26px",
  },
  backButton: {
    cursor: "pointer",
    maxHeight: "14px",
  },
  buttonText: {
    padding: "0px 10px",
    margin: "0",
    fontWeight: 500,
    fontSize: "16px",
    color: $sky90,
  },
  infoSection: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "432px",
    marginBottom: "8px",
  },
  infoDetails: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "12px",
    color: $cloud70,
    paddingBottom: "8px",
  },
  flightStatus: {
    marginBottom: "4px",
  },
  infoTimes: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "12px",
  },
  timeDisplayText: {
    color: $cloud60,
  },
  specificationSection: {
    fontSize: "14px",
    color: $cloud80,
  },
  specTitle: {
    fontWeight: 700,
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    borderBottom: `1px solid ${$cloud40}`,
    paddingBottom: "8px",
  },
  specPanel: {
    position: "relative",
    height: "444px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  panelContent: {
    overflowY: "auto",
    /*height: 100%;*/
  },
  specRow: {
    display: "flex",
    padding: "8px 4px",
    borderBottom: `1px solid ${$cloud40}`,
    fontSize: "14px",
    color: $cloud100,
    fontWeight: 500,
  },
  specTitleCell: {
    flexBasis: "50%",
    color: $cloud80,
  },
  liveTag: {
    marginTop: "3px",
  },
});
