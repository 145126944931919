import {
  makeStyles,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import mockFlightData from "../../../../../../src/mock/flights/mockFlightData.json";
import { $sky90 } from "../../../../../cssjs/colors";
import { IFlightListCard } from "../../../../../models/flights/IFlightListCard";
import FlightListTabContent from "./tab-content/FlightListTabContent";

const fetchMockData = async (type: string) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (type === "arrivals") {
        resolve(mockFlightData.arrivals);
      } else if (type === "departures") {
        resolve(mockFlightData.departures);
      } else {
        resolve([...mockFlightData.arrivals, ...mockFlightData.departures]);
      }
    }, 1000);
  });
};

interface FlightTabsProps {
  setShowFlightDetails: (prev: boolean) => void;
  setFlightCardListData: React.Dispatch<React.SetStateAction<IFlightListCard | null>>;
}

export enum FlightTabsEnum {
  All = "all",
  Arrivals = "arrival",
  Departures = "departure",
}

export const FlightTabs: React.FunctionComponent<FlightTabsProps> = ({
  setShowFlightDetails,
  setFlightCardListData,
}): React.JSX.Element => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = React.useState<TabValue>(
    FlightTabsEnum.All
  );
  const [data, setData] = useState<IFlightListCard[]>([]);

  const onTabSelect = async (_event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
    const fetchedData = await fetchMockData(data.value as string);
    setData(fetchedData as IFlightListCard[]);
  };

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchMockData(FlightTabsEnum.All);
      setData(fetchedData as IFlightListCard[]);
    };
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      <div className="fs-20 mb-2">Flights</div>
      <TabList
        className={classes.tabListContainer}
        selectedValue={selectedValue}
        onTabSelect={onTabSelect}
      >
        <Tab id="All" value={FlightTabsEnum.All}>
          All
        </Tab>
        <Tab id="Arrivals" value={FlightTabsEnum.Arrivals}>
          Arrivals
        </Tab>
        <Tab id="Departures" value={FlightTabsEnum.Departures}>
          Departures
        </Tab>
      </TabList>
      <div className={classes.panels}>
        <div className={classes.panelContent}>
          <FlightListTabContent
            classes={classes}
            data={data}
            setShowFlightDetails={setShowFlightDetails}
            setFlightCardListData={setFlightCardListData}
            type={selectedValue as FlightTabsEnum}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "24px 16px 16px 16px",
    color: "#000000",
  },
  tabListContainer: {
    width: "100%",
    justifyContent: "space-around",
    "& .fui-Tab": {
      paddingLeft: "0px",
      paddingRight: "0px",
      width: "33%",
      ":after": {
        paddingLeft: "0px",
        paddingRight: "0px",
        backgroundColor: $sky90,
        left: "0px",
        right: "0px",
      },
      ":enabled:hover::after": {
        backgroundColor: $sky90,
      },
    },
  },
  panels: {
    position: "relative",
    width: "100%",
    height: "300px",
    display: "flex",
    flexDirection: "column",
  },
  panelContent: {
    overflowY: "auto",
    /*height: 100%;*/
  },
  tabPanelContainer: {
    position: "relative",
  },
});
