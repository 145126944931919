import L from "leaflet";
import defaultFlightMarker from "./../../../assests/map-markers/defaultFlightMarker.svg";
import delayedFlightMarker from "./../../../assests/map-markers/delayedFlightMarker.svg";
import earlyFlightMarker from "./../../../assests/map-markers/earlyFlightMarker.svg";
import onTimeFlightMarker from "./../../../assests/map-markers/onTimeFlightMarker.svg";

const flightIcons = {
  onTime: new L.Icon({
    iconUrl: onTimeFlightMarker,
    iconSize: [35, 35],
    iconAnchor: [17.5, 17.5],
    tooltipAnchor: [1, -15],
  }),
  delayed: new L.Icon({
    iconUrl: delayedFlightMarker,
    iconSize: [35, 35],
    iconAnchor: [17.5, 17.5],
    tooltipAnchor: [1, -15],
  }),
  early: new L.Icon({
    iconUrl: earlyFlightMarker,
    iconSize: [35, 35],
    iconAnchor: [17.5, 17.5],
    tooltipAnchor: [1, -15],
  }),
  default: new L.Icon({
    iconUrl: defaultFlightMarker,
    iconSize: [35, 35],
    iconAnchor: [17.5, 17.5],
    tooltipAnchor: [1, -15],
  }),
};

export function getFlightIcon(status: string) {
  switch (status) {
    case "onTime":
      return flightIcons.onTime;
    case "delayed":
      return flightIcons.delayed;
    case "early":
      return flightIcons.early;
    default:
      return flightIcons.default;
  }
}
