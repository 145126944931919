import React from 'react';
import { makeStyles, Tag } from '@fluentui/react-components';
import { FlightIcon } from '../../../../../common/icons/flight/flight';
import { $forest60, $white } from '../../../../../../cssjs/colors';

interface LiveTagProps {
  liveTracked: boolean;
  height?: boolean;
}

const LiveTag: React.FC<LiveTagProps> = ({ liveTracked }) => {
  const classes = useStyles();

  if (!liveTracked) {
    return null;
  }

  return (
    <Tag
      icon={<FlightIcon />}
      size="extra-small"
      shape="rounded"
      appearance="filled"
      className={classes.liveTracking}
    >
      Live
    </Tag>
  );
};

export default LiveTag;

const useStyles = makeStyles({
    liveTracking: {
        backgroundColor: $forest60,
        color: $white,
        marginLeft: "auto",
        borderRadius: "100px",
        fontWeight: 500,
        fontSize: "10px",
        padding: "0px 2px 0 0 ",
        maxWidth: "35px",
        height:"100%",
        display: "flex",
        alignItems: "center",
        "& .fui-Tag__icon": {
          width: "auto",
          marginLeft: "-2px",
        },
        "& .fui-Tag__primaryText": {
          fontSize: "10px",
          marginLeft: "-2px",
          paddingBottom: "0"
        },
      },
});
