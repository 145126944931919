import { Marker, MarkerProps, Tooltip } from "react-leaflet";
import { tooltipStyles } from "./mapStyles";
import { airportIcon } from "./marker-icons/airportIcon";

export interface IAirportMarkersProps {
  code: string;
  name: string;
  position: MarkerProps["position"];
}

const AirportMarkers: React.FC<IAirportMarkersProps> = ({
  code,
  name,
  position,
}: IAirportMarkersProps) => {
  const classes = tooltipStyles();
  return (
    <Marker position={position} icon={airportIcon}>
      <Tooltip direction="top" className={classes.tooltip}>
        {code} - {name}
      </Tooltip>
    </Marker>
  );
};

export default AirportMarkers;
