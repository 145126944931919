import { Divider, makeStyles } from "@fluentui/react-components";
import AirportEventsCard from "./AirportEventsCard";
import AirportNotificationsCard from "./AirportNotificationsCard";
import AirportOTPCard from "./AirportOTPCard";

interface IAirportCardDetailsProps {
  otpData: any;
  eventData: any;
  notificationData: any;
  isMapView: boolean;
}

const AirportCardDetails: React.FunctionComponent<IAirportCardDetailsProps> = (
  props
) => {
  const { isMapView } = props;
  const classes = useStyles();

  const dividerClass = isMapView ? classes.mapViewDivider : "px-2";

  return (
    <div className="w-100 d-flex" data-testid="airport-card-details">
      <AirportOTPCard
        className={isMapView ? classes.containerMapView : classes.otpContainer}
        otpData={props.otpData}
        isMapView={isMapView}
      />

      <Divider vertical className={dividerClass} />

      <AirportEventsCard
        className={
          isMapView ? classes.containerMapView : classes.eventContainer
        }
        eventData={props.eventData}
        isMapView={isMapView}
      />

      <Divider vertical className={dividerClass} />

      <AirportNotificationsCard
        className={
          isMapView ? classes.containerMapView : classes.notificationContainer
        }
        notificationData={props.notificationData}
        isMapView={isMapView}
      />
    </div>
  );
};

export default AirportCardDetails;

const useStyles = makeStyles({
  otpContainer: {
    width: "45%",
    "@media screen and (max-width: 1400px)": {
      width: "35%",
    },
  },
  eventContainer: {
    width: "27%",
    "@media screen and (max-width: 1400px)": {
      width: "31%",
    },
  },
  notificationContainer: {
    width: "24%",
  },
  containerMapView: {
    width: "28%",
  },
  mapViewDivider: {
    flexGrow: 0,
  },
});
