import React from "react";
import { makeStyles } from "@fluentui/react-components";
import { $cloud100, $white } from "../../../../../cssjs/colors";
import { CommonHelper } from "../../../../../utils/common/CommonHelper";
import { AmdDonutChart } from "../../../../common/charts/DonutChart/DonutChart";
import { DonutLegendSection } from "./DonutLegendSection";
import { DonutTitleSection } from "./DonutTitleSection";
import useMediaQuery from "../../../../../hooks/useMediaQuery";


interface IProps {
  data: any;
  children?: React.ReactNode;
  isCarousel?: boolean;
}

export const DonutCard: React.FunctionComponent<IProps> = (
  props
): React.JSX.Element => {
  const { data: cardData, children, isCarousel = false } = props;
  const { data, title, subTitle } = cardData;
  const isTabletPortrait = useMediaQuery("(max-width: 1024px)");
  const isTablet = useMediaQuery("(max-width: 1366px)");
  const landscapeTablet = isTablet && !isTabletPortrait;
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.item1} fs-40`}>
        <span>{title}</span>
      </div>
      <div className={`${classes.item2}`}>
        <div className={`fs-18 ${classes.cardIcon}`}>
          {children}
        </div>
        <div className={`fs-14`}>{subTitle}</div>
      </div>
      <div
        className={`${classes.item3} ${classes.donutsContainer}`}
      >
        <div className="subItem1"></div>
        <div className="subItem2">
          <DonutTitleSection />
        </div>
        <div className="subItem3"></div>
        <div className={`${classes.legendContainer} subItem4`}>
          {data?.arrivals.map((item: any) => (
            <DonutLegendSection
              resourceTitle={item.resourceTitle}
              resourceColor={item.resourceColor}
              key={`baggage_key-${item.resourceTitle}`}
            />
          ))}
        </div>
        <div className={`${classes.chartsContainer} ${isCarousel ? classes.carouselChartsContainer : classes.defaultChartsContainer} subItem5`}>
          {Object.keys(data)?.map((key: string) => {
            return (
              <div
                className={`${classes.cardDonutChart} ${landscapeTablet ? classes.cardDonutChartTabletLandscape : undefined} ${isCarousel ? classes.carouselDonutChart : classes.defaultDonutChart}`}
                key={`${key}-${CommonHelper.getGuid()}`}
              >
                <AmdDonutChart
                  resourceData={data[key as keyof typeof data]}
                  noFlightsMessage={`No ${key}s found`}
                  hideLegend={true}
                  showCustomLegend={true}
                  radius={40}
                  width={80}
                  height={75}
                  fontSize={'16px'}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
const useStyles = makeStyles({
  gridItem: {},

  donutsContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto 0px",
  },
  chartsContainer: {
    display: "grid",
    alignContent: "flex-end",
    justifyItems: "center"
  },
  defaultChartsContainer: {
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "22px 60px",
  },
  carouselChartsContainer: {
    gridTemplateColumns: "110px 100px",
    gridTemplateRows: "22px 72px",
    marginTop: "-4px"
  },
  cardIcon: {
    lineHeigt: "1",
  },
  item1: {
    background: $white,
    lineHeight: "1",
    gridAutoFow: "column",
  },
  item2: {
    gridRow: "span 2",
    color: $cloud100,
    alignSelf: "start",
    display: "grid",
    gridTemplateColumns: "1fr",
    lineHeight: "1.2",
  },
  item3: {
    gridColumn: "1 / -1",
  },
  cardDonutChart: {
    maxWidth: "90px",
    flexDirection: "column",
    maxHeight: "80px",
  },
  defaultDonutChart: {
    marginTop: "-8px",
  },
  carouselDonutChart: {
    marginTop: "0",
  },
  cardDonutChartTabletLandscape: {
    maxWidth: "60px",
  },
  legendContainer: {
    display: "grid",
    alignContent: "center",
    lineHeight: "1.5",
  },
});