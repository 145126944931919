import { Field, makeStyles, ProgressBar } from "@fluentui/react-components";
import { $pumpkin40, $sky50, $sky60 } from "../../../../../cssjs/colors";
import { FlightTrackingIcon } from "../../../../common/icons/flight-tracking/FlightTrackingIcon";

type FlightStatusProps = {
  id?: string;
  origin?: string;
  destination?: string;
  onGround?: boolean;
  flyingStatus?: number;
  isDetails?: boolean
};

export const FlightStatus: React.FC<FlightStatusProps> = ({
  origin = "",
  destination = "",
  flyingStatus = 0,
  isDetails = false
}): React.JSX.Element => {
  const classes = useStyles();
  const onGround = !origin && !destination;
  const inFlight = flyingStatus > 0 && flyingStatus < 1;
  const iconColour = inFlight ? $pumpkin40 : $sky50;

  let iconListPosition;
  let isDestActive;
  let isOriginActive;
  let iconPositionDetails;

  switch (true) {
    case flyingStatus === 0:
      iconListPosition = classes.takeOff;
      isOriginActive = classes.originActive;
      iconPositionDetails = classes.takeOff;
      break;
    case flyingStatus === 1:
      iconListPosition = classes.landed;
      isDestActive = classes.destActive;
      iconPositionDetails = onGround ? classes.takeOff : classes.landedDetails;
      break;
    default:
      iconListPosition = classes.inFlight;
      isDestActive = classes.destActive;
      iconPositionDetails = classes.inFlightDetails;
      break;
  }

  const iconPosition = isDetails ? iconPositionDetails : iconListPosition

  return (
    <div className={classes.container}>
      <div className={`originActive ${isOriginActive}`}>{origin}</div>
      <Field
        validationMessage=""
        validationState="none"
        className={classes.progressBar}
      >
        <ProgressBar value={flyingStatus} />
        <div className={`${classes.iconContainer} ${iconPosition}`}>
          <FlightTrackingIcon
            dataTestId="flight-tracking-icon"
            color={iconColour}
          />
        </div>
      </Field>
      <div
        data-testid="flying-to"
        className={`destActive ${isDestActive}`}
      >
        {destination}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    minWidth: "142px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "500",
  },
  progressBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    position: "relative",
    paddingTop: "2px",
  },
  origin: {
    marginRight: "2px",
  },
  originActive: {
    color: $sky60,
    textDecoration: "underline",
    marginRight: "6px",
  },
  destActive: {
    color: $sky60,
    textDecoration: "underline",
    marginLeft: "4px",
  },
  dest: {
    marginLeft: "4px",
  },
  iconContainer: {
    position: "absolute",
    zIndex: "1001",
    top: "-6px",
  },
  takeOff: {
    left: "0px",
    marginLeft: "-4px",
  },
  landed: {
    left: "85%",
  },
  landedDetails: {
    left: "96%",
  },
  inFlight: {
    left: "45%",
  },
  inFlightDetails: {
    left: "49%",
  },
});
