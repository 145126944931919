import React from 'react';
import { TagPicker, TagPickerOption, TagPickerList, TagPickerControl, TagPickerGroup, TagPickerProps, Tag, Avatar, TagPickerInput } from '@fluentui/react-components';

export interface TagPickerComponentProps {
    setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
    selectedOptions: string[];
    options: string[];
    label: string;
}

const AmdTagPicker: React.FC<TagPickerComponentProps> = ({ setSelectedOptions, selectedOptions, options, label }) => {

    const onOptionSelect: TagPickerProps["onOptionSelect"] = (_e, data) => {
        if (data.value === "no-options") {
          return;
        }
        setSelectedOptions(data.selectedOptions);
      };

      const tagPickerOptions = options.filter(
        (option) => !selectedOptions.includes(option)
      );

  return (
    <TagPicker
    onOptionSelect={onOptionSelect}
    selectedOptions={selectedOptions}
    size="medium"
  >
    <TagPickerControl>
      <TagPickerGroup aria-label={`Selected ${label}`}>
        {selectedOptions.map((option) => (
          <Tag
            key={option}
            shape="rounded"
            value={option}
            media={
              <Avatar
                shape="square"
                aria-hidden
                name={option}
                color="colorful"
              />
            }
          >
            {option}
          </Tag>
        ))}
      </TagPickerGroup>
      <TagPickerInput aria-label={`Select ${label}`} placeholder={`Select ${label}`}/>
    </TagPickerControl>
    <TagPickerList>
      {tagPickerOptions.length > 0 ? (
        tagPickerOptions.map((option) => (
          <TagPickerOption
            secondaryContent=""
            value={option}
            key={option}
            media={
              <Avatar
                shape="square"
                aria-hidden
                name={option}
                color="colorful"
              />
            }
          >
            {option}
          </TagPickerOption>
        ))
      ) : (
        <TagPickerOption value="no-options">
          No options available
        </TagPickerOption>
      )}
    </TagPickerList>
  </TagPicker>
  );
};

export default AmdTagPicker;