import { makeStyles, Spinner } from "@fluentui/react-components";
import { AirplaneFilled } from "@fluentui/react-icons";
import React from "react";
import { $cloud100, $cloud30, $white } from "../../../../cssjs/colors";
import RESOURCE_DATA from "../../../../mock/overview/flight-card-donut-data.json";
import { DonutCard } from "./shared/DonutCard";

interface IProps {
  isCarousel?: boolean;
  showFlightDetails?: boolean;
}

export const FlightCard: React.FunctionComponent<IProps> = ({
  isCarousel = false,
  showFlightDetails = false,
}): React.JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [flightCardData, _setFlightData] = React.useState<any>(RESOURCE_DATA);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const classes = useStyles();
  return (
    <div className={showFlightDetails ? classes.rootHidden : undefined}>
      {loading ? (
        <Spinner
          labelPosition="below"
          label="Loading..."
          className={`${
            isCarousel ? classes.carouselCardLoader : classes.cardLoader
          }`}
        />
      ) : null}
      {!loading ? (
        <div
          className={`${classes.cardContainer} ${
            isCarousel
              ? classes.carouselCardContainer
              : classes.defaultCardContainer
          }`}
        >
          <DonutCard data={flightCardData} isCarousel={isCarousel}>
            <AirplaneFilled />
          </DonutCard>
        </div>
      ) : null}
    </div>
  );
};
const useStyles = makeStyles({
  rootHidden: {
    visibility: "hidden",
  },
  cardLoader: {
    height: "100%",
    background: $white,
    borderRadius: "8px",
  },
  carouselCardLoader: {
    height: "100%",
    background: $white,
    maxHeight: "203px",
    minHeight: "203px",
  },
  cardContainer: {
    display: "grid",
    gridGap: "8px",
    gridTemplateColumns: "auto 1fr ",
    gridTemplateRows: "32px 0px auto",
    boxSizing: "border-box",
    background: $white,
    color: $cloud100,
  },
  carouselCardContainer: {
    border: "none",
    borderRadius: "none",
    maxHeight: "203px",
    padding: "16px 57px",
    margin: "0 auto 11px auto",
  },
  defaultCardContainer: {
    borderRadius: "8px",
    padding: "8px",
    border: `1px solid ${$cloud30}`,
    minHeight: "100%",
  },
});
