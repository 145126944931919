import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Avatar, Image } from "@fluentui/react-components";
import React from "react";
import { Link } from "react-router-dom";
import PageEnum from "../../../enum/PageEnum";
import UseGraph from "../../../hooks/useGraph";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import appSettings from "../images/appSettings.svg";
import flight from "../images/flight.svg";
import signOut from "../images/signout.svg";
import userManagement from "../images/userManagement.svg";
import { getAvatarImage } from "./getAvatarImage";
import { getNavBarStyles } from "./NavBarStyles";

// Navigation Tabs
const PageNavigationTabs = [
  { url: PageEnum.PageUrl.Home, name: "Home" },
  { url: PageEnum.PageUrl.Flights360, name: "Flight 360" },
  { url: PageEnum.PageUrl.Baggage, name: "Baggage" },
  { url: PageEnum.PageUrl.Passenger, name: "Pax" },
  { url: PageEnum.PageUrl.Weather, name: "Weather" },
  { url: PageEnum.PageUrl.ResourceAllocation, name: "Resource Allocation" },
  { url: PageEnum.PageUrl.EventManagement, name: "Event Management" },
  { url: PageEnum.PageUrl.Notifications, name: "Notifications" },
];

export const NavBar: React.FunctionComponent = () => {
  const msalInstance = useMsal().instance as PublicClientApplication;

  const [userProfilePhoto, setUserProfilePhoto] = React.useState<string>("");
  const [avatar, setAvatar] = React.useState<boolean>(false);

  const setUserPhoto = () => {
    const getCurrentUser = UseGraph(
      msalInstance,
      ["user.read"],
      "https://graph.microsoft.com/v1.0/me/photos/48x48/$value"
    );

    getCurrentUser?.then((response: any) => {
      response?.blob().then((data: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          const avatarImage = getAvatarImage(reader);
          setUserProfilePhoto(avatarImage);
        };
      });
    });
  };
  React.useEffect(() => {
    setUserPhoto();
  }, []);

  const styles = getNavBarStyles();

  //Navigation Tabs Content
  const navigationTabsContent = PageNavigationTabs.map((tab, index) => (
    <div key={index} className={styles.headerNavItem}>
      <Link
        to={`/${tab.url}`}
        className={`${CommonHelper.navLinkStyle(`/${tab.url}`)} text-d-none`}
      >
        {tab.name}
      </Link>
    </div>
  ));

  return (
    <>
      <div className={styles.header}>
        <div className="d-flex">
          <div className={styles.headerBrand}>
            <Image src={flight}></Image>
          </div>
          <div className={styles.headerBrandName}>
            <Link to={`/${PageEnum.PageUrl.Home}`}>APOC - AOM</Link>
          </div>
        </div>

        <div className="amd-container-hstart">{navigationTabsContent}</div>
        <div
          className="pt-1 pr-sm-1 cursor-pointer"
          onClick={() => setAvatar(!avatar)}
        >
          <Avatar
            data-testid="avatar-image"
            image={{ src: userProfilePhoto }}
          />
        </div>
      </div>
      {avatar && (
        <div className={styles.headerSubNavItem}>
          <Link
            to={`/${PageEnum.PageUrl.Admin}`}
            className={`amd-container-vcenter text-d-none fw-400 fs-16 pa-2 ${CommonHelper.navLinkActive(
              `/${PageEnum.PageUrl.Admin}`
            )}`}
          >
            <span style={{ width: 20 }}>
              <Image src={userManagement} />
            </span>
            <span className="ml-2 fc--gray800">User Management</span>
          </Link>
          <Link
            to={`/${PageEnum.PageUrl.ApplicationSettings}`}
            className={`amd-container-vcenter text-d-none fw-400 fs-16 pa-2 ${CommonHelper.navLinkActive(
              `/${PageEnum.PageUrl.ApplicationSettings}`
            )}`}
          >
            <span style={{ width: 20 }}>
              <Image src={appSettings} />
            </span>
            <span className="ml-2 fc--gray800">Application Settings</span>
          </Link>
          <Link
            to="#"
            onClick={CommonHelper.logoutUser(msalInstance)}
            className={`amd-container-vcenter text-d-none fw-400 fs-16 pa-2`}
            style={{ borderTop: "1px solid #cccccc" }}
          >
            <span style={{ width: 20 }}>
              <Image src={signOut} />
            </span>
            <span className="ml-2 fc--gray800">Sign out</span>
          </Link>
        </div>
      )}
    </>
  );
};

