import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const FlightTrackingIcon: React.FunctionComponent<IAmdIconProps> = ({ color = '#FF8838' }) => {
  return (
    <svg width="13" height="12" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="flight-tracking-icon">
    <path d="M9.90967 4.22705C10.5737 4.22705 11.7456 4.79346 11.7456 5.47705C11.7456 6.18018 10.5737 6.72705 9.90967 6.72705H7.62451L5.67139 10.1646C5.5542 10.3599 5.33936 10.4771 5.12451 10.4771H4.03076C3.81592 10.4771 3.65967 10.2817 3.71826 10.0864L4.67529 6.72705H2.68311L1.82373 7.85986C1.76514 7.93799 1.68701 7.97705 1.58936 7.97705H0.769043C0.612793 7.97705 0.495605 7.85986 0.495605 7.70361C0.495605 7.68408 0.495605 7.66455 0.495605 7.64502L1.12061 5.47705L0.495605 3.32861C0.495605 3.30908 0.495605 3.28955 0.495605 3.25049C0.495605 3.11377 0.612793 2.97705 0.769043 2.97705H1.58936C1.68701 2.97705 1.76514 3.03564 1.82373 3.11377L2.68311 4.22705H4.67529L3.71826 0.887207C3.65967 0.691895 3.81592 0.477051 4.03076 0.477051H5.12451C5.33936 0.477051 5.5542 0.61377 5.67139 0.809082L7.62451 4.22705H9.90967Z" fill={color}/>
    </svg>

  );
};
