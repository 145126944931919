import { SearchBoxChangeEvent } from "@fluentui/react-components";
import React from "react";
import Sidebar from "../common/sidebar/Sidebar";
import AirportList from "./AirportList";

interface IAirportListProps {
  airportList: any;
  currentTime: Date;
  onPinClickHandler: (id: string) => void;
  sideBarActive: boolean;
  setSideBarActive: any;
  searchInputHandler: (event: SearchBoxChangeEvent) => void;
}

const MultiAirportSidebar: React.FunctionComponent<IAirportListProps> = ({
  airportList,
  onPinClickHandler,
  currentTime,
  sideBarActive,
  setSideBarActive,
  searchInputHandler,
}) => {

  return (
    <Sidebar
      sideBarActive={sideBarActive}
      setSideBarActive={setSideBarActive}
      searchInputHandler={searchInputHandler}
    >
      <AirportList
        airportList={airportList}
        onPinClickHandler={onPinClickHandler}
        currentTime={currentTime}
      />
    </Sidebar>
  );
};

export default MultiAirportSidebar;