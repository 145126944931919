import {
  Carousel,
  CarouselCard,
  CarouselNav,
  CarouselNavButton,
  CarouselNavContainer,
  CarouselSlider,
  makeStyles,
} from "@fluentui/react-components";
import * as React from "react";
import { $white } from "../../cssjs/colors";
import { EventsCard } from "../shared/components/airport-cards/EventsCard";
import { FlightCard } from "../shared/components/airport-cards/FlightCard";
import { FlightTabs } from "../shared/components/airport-cards/flights/FlightTabs";
import { WeatherCard } from "../shared/components/airport-cards/WeatherCard";
import { FlightDetails } from "../shared/components/airport-cards/flights/tab-content/FlightDetails";
import { IFlightListCard } from "../../models/flights/IFlightListCard";

const BannerCard: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const { children } = props;
  const classes = useClasses();

  return (
    <CarouselCard className={classes.bannerCard} aria-label="Overview Carousel">
      {children}
    </CarouselCard>
  );
};

export const OverviewCarousel: React.FC = () => {
  const classes = useClasses();
  const [showFlightDetails, setShowFlightDetails] = React.useState<boolean>(false);
  const [flightCardListData, setFlightCardListData] = React.useState<IFlightListCard | null>(null);
  const CARDS = [
    <>
      <FlightCard key="carouselFlightCard" isCarousel={true} showFlightDetails={showFlightDetails}/>
      <div className={`${showFlightDetails ? classes.carouselHidden : undefined}`}><FlightTabs key="carouselFlightDetailsCard" setShowFlightDetails={setShowFlightDetails} setFlightCardListData={setFlightCardListData}/></div>
    </>,
    <WeatherCard key="carouselWeatherCard" isCarousel={true} />,
    <EventsCard key="carouselEventsCard" isCarousel={true} />,
  ];

  return (
    <div className={classes.container}>
      {showFlightDetails && <FlightDetails setShowFlightDetails={setShowFlightDetails} flightCardListData={flightCardListData}/>}
      <Carousel groupSize={1} className={classes.carouselContainer} circular>
        <CarouselSlider>
          {CARDS.map((item, index) => (
            <BannerCard key={`bannerCard-${index.toString()}`} >
              {item}
            </BannerCard>
          ))}
        </CarouselSlider>
        <div className={`${showFlightDetails ? classes.carouselHidden : undefined}`}>
        <CarouselNavContainer
          layout="overlay-expanded"
          next={{ "aria-label": "go to next" }}
          prev={{ "aria-label": "go to prev" }}
          className={`${classes.carouselNavContainer}`}
        >
          <CarouselNav
            className={`${classes.carouselNav}`}>
            {(index: any) => (
              <CarouselNavButton
                aria-label={`Carousel Nav Button ${index}`}
                className={`${classes.carouselNavButton}`}
              />
            )}
          </CarouselNav>
        </CarouselNavContainer>
        </div>
      </Carousel>
    </div>
  );
};

const useClasses = makeStyles({
  carouselHidden: {
    visibility: "hidden",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    minHeight: "640px",
    background: $white,
  },
  carouselContainer: {
    minHeight: "243px",
    color: $white,
    paddingTop: "1px",
  },
  bannerCard: {
    textAlign: "left",
    position: "relative",
    minHeight: "100%",
    height: "640px",
  },
  controls: {},
  carouselNav: {
    margin: "0px",
    background: "#737373",
    display: "flex",
    justifyContent: "center",
    borderRadius: "0",
    width: "100%",
    height: "24px",
    maxWidth: "368px",
  },
  carouselNavButton: {
    width: "30px",
    padding: "8px",
    height: "3px",
    "&::after": {
      color: $white,
      background: $white,
      width: "30px",
      height: "3px",
      borderRadius: "none",
    },
  },
  carouselNavContainer: {
    "& .fui-CarouselButton": {
      background: "#888888",
      opacity: "1",
      borderRadius: "30px",
      color: $white,
      ":hover": {
        background: "#B9B9B9",
        opacity: "0.9",
      },
      ":first-child": {
        left: "16px",
        top: "100px",
      },
      ":last-child": {
        right: "16px",
        top: "100px",
      },
    },
    "& .fui-CarouselNav": {
      bottom: "424px",
    },
  },
});
