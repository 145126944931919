import {
  makeStyles,
  SearchBox,
  SearchBoxChangeEvent,
  ToggleButton,
} from "@fluentui/react-components";
import React from "react";
import { useLocation } from "react-router-dom";
import { $cloud20, $primary, $white } from "../../../cssjs/colors";
import { BarsIcon } from "../icons/bars/BarsIcon";
import mockFlightData from "../../../../src/mock/flights/mockFlightData.json";
import { FlightListContext } from "../../../context/FlightListContext";
import AmdTagPicker from "../tag-picker/TagPicker";

interface IAirportListProps {
  isMapView?: boolean;
  sideBarActive: boolean;
  setSideBarActive: any;
  searchInputHandler: (event: SearchBoxChangeEvent) => void;
  children?: React.ReactElement;
}

const extractIds = (data: any[]): string[] => {
  return data.map((flight) => flight.flightNumber);
};

const Sidebar: React.FunctionComponent<IAirportListProps> = ({
  sideBarActive,
  setSideBarActive,
  searchInputHandler,
  children,
}) => {
  const classes = useStyles();
  const burgerMenuHandler = React.useCallback(() => {
    setSideBarActive((prev: boolean) => !prev);
  }, [setSideBarActive]);

  const location = useLocation() ?? "";
  const isMultiAirportPage = location?.pathname?.startsWith("/multi-airports");
  const isFlights360Page = location?.pathname?.startsWith("/flights360");
  const isOverview = location?.pathname?.startsWith("/overview");
  const showTagPicker = isFlights360Page || isOverview;
  let multiAirportStyles;

  if (sideBarActive && isMultiAirportPage) {
    multiAirportStyles = classes.multiAirportContainerOpen;
  } else if (!sideBarActive && isMultiAirportPage) {
    multiAirportStyles = classes.multiAirportContainerClosed;
  } else {
    multiAirportStyles = undefined;
  }

  const placeholderText = isMultiAirportPage
    ? "Search airport"
    : "Search flight";

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

  const options = extractIds([
    ...mockFlightData.arrivals,
    ...mockFlightData.departures,
  ]);

  const contextValue = React.useMemo(() => ({ selectedOptions }), [selectedOptions]);

  return (
    <>
      <section
        data-testid="sidebar-container"
        className={`${classes.container} ${
          sideBarActive ? classes.containerOpen : undefined
        } ${multiAirportStyles}`}
      >
        <header
          className={`mapview-sidebar ${classes.header} ${
            sideBarActive ? classes.headerOpen : classes.headerClosed
          } ${
            isMultiAirportPage && !sideBarActive
              ? classes.multiAirportHeaderClosed
              : undefined
          }`}
        >
          <ToggleButton
            onClick={burgerMenuHandler}
            className={`${classes.burgerMenu}`}
            icon={<BarsIcon />}
            aria-label="Open the menu"
          />
          {showTagPicker ? (
            <div className={classes.tagPicker}>
              <AmdTagPicker setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} options={options}
              label="Flights"
              />
            </div>
          ) : (
            <SearchBox
              placeholder={placeholderText}
              onChange={(event: SearchBoxChangeEvent) => {
                searchInputHandler(event);
              }}
              size="medium"
              appearance="outline"
              aria-label="search"
              className={classes.sidebar}
            />
          )}
        </header>
        <FlightListContext.Provider value={contextValue}>
        {sideBarActive ? children : null}
        </FlightListContext.Provider>
      </section>
    </>
  );
};

export default Sidebar;

const useStyles = makeStyles({
  container: {
    position: "relative",
    zIndex: 3,
    maxWidth: "400px",
    borderRadius: "8px",
    marginLeft: "8px",
    marginBottom: "16px",
  },
  containerOpen: {
    background: $cloud20,
    marginLeft: "16px",
  },
  multiAirportContainerOpen: {
    marginTop: "-32px",
  },
  multiAirportContainerClosed: {
    marginTop: "-40px",
  },
  header: {
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    display: "flex",
    justifyContent: "flex-start",
    color: $white,
  },
  headerOpen: {
    background: $white,
    padding: "16px 16px 0 16px",
  },
  headerClosed: {
    padding: "0px 8px 16px",
  },
  multiAirportHeaderClosed: {
    padding: "8px 8px 16px",
  },
  sidebar: {
    marginTop: "0",
    minWidth: "280px",
    marginRight: "4px",
    width: "100%",
  },
  burgerMenu: {
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${$primary}`,
    padding: "12px",
    minWidth: "42px",
    maxHeight: "36px",
    background: $white,
    color: $primary,
    "&:hover": {
      border: `1px solid ${$primary}`,
      background: $primary,
      color: $white,
      fill: $white,
    },
    marginRight: "16px",
  },
  tagPicker: {
    width: "100%",
  "& .fui-Tag__media": {
    paddingRight: "0px"
  }
  },
});
