import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Layout from "../layout/Layout";

export const RootLayout: FC = (): React.JSX.Element => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
