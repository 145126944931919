import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";
import { ChevronDirection } from "./chevronDirection";

export const AmdChevron: React.FunctionComponent<IAmdIconProps> = ({
  direction = ChevronDirection.RIGHT,
  color = "#0C66E1",
  ...props
}) => {
  let arrowClass = { root: "" };
  if (direction === ChevronDirection.LEFT) {
    arrowClass = mergeStyleSets({
      root: {
        transform: "rotate(180deg)",
      },
    });
  } else if (direction === ChevronDirection.DOWN) {
    arrowClass = mergeStyleSets({
      root: {
        transform: "rotate(90deg)",
      },
    });
  } else if (direction === ChevronDirection.UP) {
    arrowClass = mergeStyleSets({
      root: {
        transform: "rotate(270deg)",
      },
    });
  }
  return (
    <span className={props.className}>
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        className={arrowClass.root}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
      >
<path d="M8.05322 13.813C7.89697 13.9692 7.67822 13.9692 7.52197 13.813L0.959473 7.28174C0.834473 7.12549 0.834473 6.90674 0.959473 6.75049L7.52197 0.219238C7.67822 0.0629883 7.89697 0.0629883 8.05322 0.219238L8.67822 0.812988C8.80322 0.969238 8.80322 1.21924 8.67822 1.34424L3.02197 7.00049L8.67822 12.688C8.80322 12.813 8.80322 13.063 8.67822 13.2192L8.05322 13.813Z" fill={color} />
      </svg>
    </span>
  );
};
