import { makeStyles, mergeClasses } from "@fluentui/react-components";
import { CalendarDayIcon } from "../common/icons/calendar/CalendarDayIcon";
import { CalendarExclamationIcon } from "../common/icons/calendar/CalendarExclamationIcon";
import { CalendarWeekIcon } from "../common/icons/calendar/CalendarWeekIcon";

interface IAirportEventsCardProps {
  className?: string;
  eventData: any;
  isMapView?: boolean;
}

const AirportEventsCard: React.FunctionComponent<IAirportEventsCardProps> = ({
  className,
  eventData,
  isMapView = false,
}) => {
  const classes = useStyles();
  return (
    <div
      className={mergeClasses(className, classes.container)}
      data-testid="airport-event-card"
    >
      <header className={classes.title}>Events</header>
      <div className="d-flex">
        <div className="flex-1">
          <header className="fs-20 pb-sm-1">
            <CalendarDayIcon className={classes.icon} />
            <span>{`${eventData.low}`}</span>
          </header>
          <footer>Low</footer>
        </div>
        {!isMapView && (
          <>
            <div className="flex-1">
              <header className="fs-20 pb-sm-1">
                <CalendarWeekIcon className={classes.icon} />
                <span>{`${eventData.medium}`}</span>
              </header>
              <footer>Medium</footer>
            </div>
            <div className="flex-1">
              <header className="fs-20 pb-sm-1">
                <CalendarExclamationIcon className={classes.icon} />
                <span>{`${eventData.high}`}</span>
              </header>
              <footer>High</footer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AirportEventsCard;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    padding: "6px 12px",
  },
  title: { color: "#666666", fontWeight: 500 },
  icon: {
    paddingRight: "4px",
  },
});
